import { ResponseError } from '@hts/partner-airlines-api-client';
import {
  APIErrorCode,
  ErrorCode,
  errorCodeForApiError,
} from '../constants/errorCode';
import { CONTENT_TYPE_JSON } from '../constants/http';

// Generated API client throws non-2xx responses but other errors are also possible
export const responseErrorCode = async (
  value: ResponseError
): Promise<ErrorCode> => {
  let code = APIErrorCode.Unknown;

  // Includes instanceof Error
  if (!(value instanceof ResponseError)) {
    return ErrorCode.Generic;
  }

  const response = value.response.clone();
  const contentType = response.headers.get('content-type');

  if (contentType === CONTENT_TYPE_JSON) {
    const { errors } = await response.json();
    if (Array.isArray(errors) && errors.length > 0) {
      [{ code }] = errors;
    }
  } else {
    code = (await response.text()) as APIErrorCode;
  }

  return errorCodeForApiError(code);
};
