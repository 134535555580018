export const typographyDefaults = {
  fontSize: 14,
  h1: {
    fontSize: '2.8rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.7rem',
    fontWeight: 700,
  },
};
