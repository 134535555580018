import * as v from 'valibot';
import { setInitialData } from '../../products/cfar/store';

const paramSchema = v.object({
  hSessionId: v.pipe(v.string(), v.nonEmpty()),
  exerciseId: v.pipe(v.string(), v.nonEmpty()),
  callbackUrl: v.optional(v.string()),
  redirectbackUrl: v.optional(v.string()),
});

export function sanitizeUrl() {
  const url = new URL(window.location.href.split('#/').join(''));
  const paramsObject = Object.fromEntries(url.searchParams);
  const result = v.safeParse(paramSchema, paramsObject);
  if (result.success) {
    const {
      hSessionId: sessionId,
      exerciseId,
      callbackUrl,
      redirectbackUrl: redirectBackUrl,
    } = result.output;
    setInitialData({ sessionId, redirectBackUrl, callbackUrl, exerciseId });
    url.hash = '';
    url.search = '';
  }
  // Always remove the hash
  window.history.replaceState({}, '', url.toString());
}
