import { useTranslation } from 'react-i18next';
import { namespaceForPartner } from '../../../../i18n/utilities/namespaceForPartner';
import { useCfarStore } from '../../store';
import { useMemo } from 'react';

export function usePartnerTranslation() {
  const partnerId = useCfarStore(({ partnerId }) => partnerId);
  const partnerNamespaces = useMemo(
    () => namespaceForPartner(partnerId),
    [partnerId]
  );
  return useTranslation(partnerNamespaces);
}
