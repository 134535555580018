import { Logger } from './logger';

const storedContext: Record<string, string> = {};

export function setDatadogContext(context: Record<string, string>) {
  Object.entries(context).forEach(([key, value]) => {
    storedContext[key] = value;
  });
  Logger.setGlobalContext(storedContext);
  import('@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.setGlobalContext({
      data: {
        jsonPayload: storedContext,
      },
    });
  });
}
