import { Trans, useTranslation } from 'react-i18next';
import { ErrorCode } from '../../constants/errorCode';
import Link from '@mui/material/Link';
import { useQuery } from '@tanstack/react-query';
import { sessionOptions } from '../../../products/cfar/queries/sessionOptions';
import { useCfarStore } from '../../../products/cfar/store';

export function useErrorMessage(code: ErrorCode) {
  const { t } = useTranslation();
  const { sessionId } = useCfarStore();
  const { data } = useQuery({
    ...sessionOptions(sessionId),
    throwOnError: false,
  });
  switch (code) {
    case ErrorCode.NotAuthorized:
      return t('SESSION_EXPIRED_DIALOG.DESCRIPTION');
    case ErrorCode.ContactSupport: {
      const contactUrl = data?.contactFormUrl;
      if (contactUrl) {
        return (
          <Trans
            i18nKey={'CFAR_EXERCISE_FLOW.CONTACT_SUPPORT_MESSAGE'}
            components={[
              <Link href={contactUrl} target="_blank" rel="noopener" />,
            ]}
          />
        );
      } else {
        return t('ERROR_DEFAULT');
      }
    }
    case ErrorCode.UrgentContactSupport:
      return t('CFAR_EXERCISE_FLOW.URGENT_CONTACT_SUPPORT');
    case ErrorCode.InvalidSearchParams:
      return t('HOME.INVALID_PARAMETERS');
    case ErrorCode.InvalidState:
    case ErrorCode.Generic:
    default:
      return t('ERROR_DEFAULT');
  }
}
