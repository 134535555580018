/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticateImport } from './routes/authenticate'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as AuthenticatedExerciseImport } from './routes/_authenticated.exercise'

// Create/Update Routes

const AuthenticateRoute = AuthenticateImport.update({
  id: '/authenticate',
  path: '/authenticate',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedExerciseRoute = AuthenticatedExerciseImport.update({
  id: '/exercise',
  path: '/exercise',
  getParentRoute: () => AuthenticatedRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/authenticate': {
      id: '/authenticate'
      path: '/authenticate'
      fullPath: '/authenticate'
      preLoaderRoute: typeof AuthenticateImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/exercise': {
      id: '/_authenticated/exercise'
      path: '/exercise'
      fullPath: '/exercise'
      preLoaderRoute: typeof AuthenticatedExerciseImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedExerciseRoute: typeof AuthenticatedExerciseRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedExerciseRoute: AuthenticatedExerciseRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/exercise': typeof AuthenticatedExerciseRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/exercise': typeof AuthenticatedExerciseRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/authenticate': typeof AuthenticateRoute
  '/_authenticated/exercise': typeof AuthenticatedExerciseRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths: '/' | '' | '/authenticate' | '/exercise'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '' | '/authenticate' | '/exercise'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/authenticate'
    | '/_authenticated/exercise'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AuthenticateRoute: typeof AuthenticateRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AuthenticateRoute: AuthenticateRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/authenticate"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/exercise"
      ]
    },
    "/authenticate": {
      "filePath": "authenticate.tsx"
    },
    "/_authenticated/exercise": {
      "filePath": "_authenticated.exercise.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
