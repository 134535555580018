import { Partner } from '../constants/partner';

const PartnerIconNames = new Map<string, string>([
  [Partner.HtsAirline, 'default'],
  [Partner.VirginAustralia, 'va'],
  [Partner.Volaris, 'y4'],
]);

function getPartnerLogoUrl(partnerCode: string) {
  return new URL(`../../assets/logos/logo-${partnerCode}.svg`, import.meta.url)
    .href;
}

export function iconForPartner(partnerId: string) {
  const partnerCode = PartnerIconNames.get(partnerId) || 'default';
  return getPartnerLogoUrl(partnerCode);
}
