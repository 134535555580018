import {
  Typography,
  Paper,
  Box,
  Container,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import {
  createRootRouteWithContext,
  ErrorComponentProps,
  Outlet,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { LanguageMenu } from '../common/components/LanguageMenu';
import { Footer } from '../common/components/Footer';
import { QueryClient } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material';
import { usePartnerRedirect } from '../products/cfar/components/hooks/usePartnerRedirect';
import {
  HEADER_FOOTER_HEIGHT,
  VIEWPORT_HEIGHT,
} from '../common/constants/layout';
import { toErrorCode } from '../common/constants/errorCode';
import { ErrorDisplay } from '../common/components/ErrorDisplay';
import { useMemo } from 'react';
import { Partner } from '../common/constants/partner';
import { themeForPartnerId } from '../common/utilities/themeForPartnerId';
import { iconForPartner } from '../common/utilities/iconForPartner';
import { setPartnerId, useCfarStore } from '../products/cfar/store';
import { usePartnerTranslation } from '../products/cfar/components/hooks/usePartnerTranslation';

const RootComponent = () => {
  const { t } = usePartnerTranslation();
  const { partnerId } = useCfarStore(({ partnerId }) => ({
    partnerId,
  }));

  const partnerRedirect = usePartnerRedirect();
  const theme = useMemo(() => themeForPartnerId(partnerId), [partnerId]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: VIEWPORT_HEIGHT,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#fafafa',
        }}
      >
        <Box
          component="nav"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: HEADER_FOOTER_HEIGHT,
            px: [2, 4],
            borderRadius: 0,
            bgcolor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          <Box display="flex" flexDirection="row">
            <Box
              component="img"
              sx={{
                width: '100%',
                maxHeight: '48px',
              }}
              onError={(e) => (e.currentTarget.style.display = 'none')}
              src={iconForPartner(partnerId)}
            />
          </Box>

          <Box display="flex" flexDirection="row" gap={2}>
            {import.meta.env.DEV && (
              <FormControl
                sx={{
                  display: 'flex',
                }}
              >
                <Select
                  id="theme-selector"
                  value={partnerId}
                  label="Theme"
                  onChange={(e) => {
                    setPartnerId(e.target.value as Partner);
                  }}
                  sx={{
                    color: (theme) => theme.palette.primary.contrastText,
                  }}
                >
                  <MenuItem value={Partner.HtsAirline}>HTS Airline</MenuItem>
                  <MenuItem value={Partner.VirginAustralia}>
                    Virgin Australia
                  </MenuItem>
                  <MenuItem value={Partner.Volaris}>Volaris</MenuItem>
                </Select>
              </FormControl>
            )}

            <LanguageMenu />
          </Box>
        </Box>

        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: `calc(${VIEWPORT_HEIGHT} - (2 * ${HEADER_FOOTER_HEIGHT}))`,
            padding: [0, 2, 4],
            overflowY: 'auto',
          }}
        >
          <Paper
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 3,
              maxWidth: [undefined, '800px'],
            }}
          >
            <Typography textAlign="center" variant="h1">
              {t('CFAR_EXERCISE_FLOW.TITLE')}
            </Typography>
            <Divider />
            <Outlet />
          </Paper>

          {partnerRedirect && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              p={2}
            >
              <Button
                variant="text"
                sx={{
                  textTransform: 'none',
                  gap: 1,
                }}
                onClick={partnerRedirect.handler}
                data-testid="redirectBack"
                color={'secondary'}
              >
                <ExitToApp />
                {t('CFAR_EXERCISE_FLOW.BUTTON.GO_BACK_TO_PARTNER', {
                  partnerName: partnerRedirect.partnerName,
                })}
              </Button>
            </Box>
          )}
        </Container>

        <Footer />

        {import.meta.env.DEV && <TanStackRouterDevtools />}
      </Box>
    </ThemeProvider>
  );
};

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const errorCode = toErrorCode(error.message);
  return <ErrorDisplay errorCode={errorCode} />;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  errorComponent: ErrorComponent,
  component: RootComponent,
});
