import { ErrorComponentProps } from '@tanstack/react-router';
import { ErrorDisplay } from './ErrorDisplay';
import { ErrorCode, toErrorCode } from '../constants/errorCode';
import { ResponseError } from '@hts/partner-airlines-api-client';
import { responseErrorCode } from '../utilities/responseErrorCode';
import { useEffect, useState } from 'react';
import { STATUS_CODE_UNAUTHORIZED } from '../constants/http';
import { Logger } from '../../products/cfar/utilities/logger';
import { clearAuthentication } from '../../products/cfar/store';

export const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const [errorCode, setErrorCode] = useState(toErrorCode(error.message));

  useEffect(() => {
    Logger.error(`ErrorComponent: ${error.message}`, {}, error);
    if (error instanceof ResponseError) {
      if (error.response.status === STATUS_CODE_UNAUTHORIZED) {
        setErrorCode(ErrorCode.NotAuthorized);
        clearAuthentication();
      } else {
        responseErrorCode(error).then((code) => {
          setErrorCode(code);
        });
      }
    }
  }, [error]);

  return <ErrorDisplay errorCode={errorCode} />;
};
