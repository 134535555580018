import { getClient } from './getClient';

export async function checkVerificationCode(
  sessionId: string,
  exerciseId: string,
  verificationCode: string
) {
  const client = getClient(sessionId);
  return client.postCustomerIdCheckExerciseVerificationCode({
    id: exerciseId,
    checkCfarContractExerciseVerificationCodeRequest: {
      verificationCode,
    },
  });
}
