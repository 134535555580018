import {
  AnalyticsApi,
  CancelForAnyReasonCFARApi,
  Configuration,
} from '@hts/partner-airlines-api-client';
import { API_URL } from '../../../config';

export function getClient(sessionId: string) {
  const configuration = new Configuration({
    basePath: API_URL,
    apiKey: sessionId,
  });
  return new CancelForAnyReasonCFARApi(configuration);
}

export function getClientAnalytics(sessionId: string) {
  const configuration = new Configuration({
    basePath: API_URL,
    apiKey: sessionId,
  });
  return new AnalyticsApi(configuration);
}
