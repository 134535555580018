export enum SupportedLocale {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  ID = 'id',
  JA = 'ja',
  KM_KH = 'km-kh',
  KO = 'ko',
  MS = 'ms',
  RU = 'ru',
  TH = 'th',
  TR = 'tr',
  VI = 'vi',
  ZH_HANS = 'zh-hans',
  ZH_HANT = 'zh-hant',
}

export const SUPPORTED_LOCALES = Object.values(SupportedLocale);
export const DEFAULT_NAMESPACE = 'common';
export const DEFAULT_LNG = SupportedLocale.EN;
