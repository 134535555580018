import { getClient } from './getClient';

export async function sendVerificationCode(
  sessionId: string,
  exerciseId: string
) {
  const client = getClient(sessionId);
  return client.postCustomerIdSendExerciseVerificationCode({
    id: exerciseId,
    body: {},
  });
}
