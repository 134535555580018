import { StatusType, datadogLogs } from '@datadog/browser-logs';

const DEFAULT_DD_SITE = 'us5.datadoghq.com';

type LoggerInitOptions = {
  site?: string;
  clientToken: string;
  service: string;
  currentEnvironment: string;
  version: string;
};

type LogHandler = (
  message: string,
  messageContext?: Record<string, string>,
  error?: Error
) => void;

type Logger = Record<StatusType, LogHandler> & {
  setGlobalContext: (context: Record<string, string>) => void;
};

export function createLogger({
  site = DEFAULT_DD_SITE,
  clientToken,
  service,
  currentEnvironment,
  version,
}: LoggerInitOptions): Logger {
  datadogLogs.init({
    site,
    clientToken,
    service,
    env: currentEnvironment,
    forwardErrorsToLogs: true,
    version,
  });

  const loggerHandler =
    currentEnvironment === 'production'
      ? 'http'
      : ['http' as const, 'console' as const];

  datadogLogs.onReady(() => {
    datadogLogs.logger.setHandler(loggerHandler);
  });

  const logWithLevel =
    (level: StatusType): LogHandler =>
    (message, context, error) =>
      datadogLogs.logger[level](message, context, error);

  const setGlobalContext = (context: Record<string, string>) => {
    datadogLogs.setGlobalContext({
      data: {
        jsonPayload: context,
      },
    });
  };

  // returns a logger instance containing a handler for each StatusType
  return Object.values(StatusType).reduce(
    (prev, curr) => ({ ...prev, [curr]: logWithLevel(curr) }),
    { setGlobalContext } as Logger
  );
}
