export enum ErrorCode {
  Generic = 'unexpected_error',
  InvalidSearchParams = 'invalid_params',
  InvalidState = 'invalid_state',
  NotAuthorized = 'unauthorized',
  InvalidCredential = 'invalid_credential',
  ContactSupport = 'contact_support',
  UrgentContactSupport = 'urgent_contact_support',
}

export enum APIErrorCode {
  InvalidCredentials = 'EX019',
  CodeAlreadySent = 'EX037',

  // The contract is not eligible for automatic exercise.
  ContractIneligibleAutoExercise = 'EX165',

  UrgentContactSupport = 'EX000',

  Unknown = 'Unknown',
}

const ErrorCodeLookup = new Map<string, ErrorCode>([
  [APIErrorCode.UrgentContactSupport, ErrorCode.UrgentContactSupport],
  [APIErrorCode.ContractIneligibleAutoExercise, ErrorCode.ContactSupport],
]);

const ERROR_CODE_VALUES = Object.values(ErrorCode);
const API_ERROR_CODE_VALUES = Object.values(APIErrorCode);

export const isErrorCode = (message: string): message is ErrorCode =>
  ERROR_CODE_VALUES.includes(message as ErrorCode);

export const isAPIErrorCode = (code: string): code is APIErrorCode =>
  API_ERROR_CODE_VALUES.includes(code as APIErrorCode);

export const toErrorCode = (message: string | ErrorCode): ErrorCode =>
  isErrorCode(message) ? message : ErrorCode.Generic;

export const errorCodeForApiError = (code: string): ErrorCode =>
  ErrorCodeLookup.get(code) || ErrorCode.Generic;
