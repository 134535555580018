import {
  CfarEvent,
  CfarExerciseCallbackLaunched,
  CfarExerciseCustomerDataComplete,
  CfarExercisePortalComplete,
  CfarExercisePortalDisplay,
  CfarExerciseVerificationComplete,
  CfarExerciseVerificationSent,
  ExerciseStepResult,
} from '@hts/partner-airlines-api-client';
import { getClientAnalytics } from './getClient';
import { Logger } from '../utilities/logger';

export async function cfarEvent(
  sessionId: string,
  exerciseId: string,
  eventType:
    | 'cfar_exercise_callback_launched'
    | 'cfar_exercise_customer_data_complete'
    | 'cfar_exercise_portal_complete'
    | 'cfar_exercise_portal_display'
    | 'cfar_exercise_verification_complete'
    | 'cfar_exercise_verification_sent',
  exerciseStepResult?: ExerciseStepResult
) {
  const client = getClientAnalytics(sessionId);

  const buildEvent = () => {
    let event = {
      occurredDateTime: new Date(),
      cfarExerciseId: exerciseId,
    };

    switch (eventType) {
      case 'cfar_exercise_callback_launched':
        event = {
          ...event,
          result: exerciseStepResult,
          type: 'cfar_exercise_callback_launched' as const,
        } as CfarExerciseCallbackLaunched;
        break;
      case 'cfar_exercise_customer_data_complete':
        event = {
          ...event,
          result: exerciseStepResult,
          type: 'cfar_exercise_customer_data_complete' as const,
        } as CfarExerciseCustomerDataComplete;
        break;
      case 'cfar_exercise_portal_complete':
        event = {
          ...event,
          result: exerciseStepResult,
          type: 'cfar_exercise_portal_complete' as const,
        } as CfarExercisePortalComplete;
        break;
      case 'cfar_exercise_portal_display':
        event = {
          ...event,
          type: 'cfar_exercise_portal_display' as const,
        } as CfarExercisePortalDisplay;
        break;
      case 'cfar_exercise_verification_complete':
        event = {
          ...event,
          result: exerciseStepResult,
          type: 'cfar_exercise_verification_complete' as const,
        } as CfarExerciseVerificationComplete;
        break;
      case 'cfar_exercise_verification_sent':
        event = {
          ...event,
          type: 'cfar_exercise_verification_sent' as const,
        } as CfarExerciseVerificationSent;
        break;
    }

    return event;
  };

  try {
    return await client.postCustomerEvents({
      cfarEvent: buildEvent() as CfarEvent,
    });
  } catch (e) {
    const error = e as Error;
    Logger.error(
      'An error occurred while sending the event : ' + error?.message,
      {},
      error
    );
  }
}
