import { useQuery } from '@tanstack/react-query';
import { Partner } from '../../../../common/constants/partner';
import { useCfarStore } from '../../store';
import { createRedirectUrl } from '../../utilities/createRedirectUrl';
import { Logger } from '../../utilities/logger';
import { sessionOptions } from '../../queries/sessionOptions';

const VA_REDIRECT_BACK_URL =
  'https://www.virginaustralia.com/au/en/book/manage-booking/cancel-for-any-reason/';

export function usePartnerRedirect() {
  const { sessionId, redirectBackUrl, isFlowCompleted } = useCfarStore(
    ({ sessionId, redirectBackUrl, isFlowCompleted }) => ({
      sessionId,
      isFlowCompleted,
      redirectBackUrl,
    })
  );

  const { isSuccess, data } = useQuery({
    ...sessionOptions(sessionId),
    throwOnError: false,
  });

  if (isFlowCompleted || !isSuccess) {
    return null;
  }

  const { partnerId, partnerName } = data;

  let targetUrl = redirectBackUrl;

  if (partnerId === Partner.VirginAustralia) {
    targetUrl = VA_REDIRECT_BACK_URL;
  }

  if (!targetUrl) {
    return null;
  }

  return {
    handler: () => {
      try {
        Logger.info(
          `redirecting user to partner-provided redirectbackUrl: ${targetUrl}`
        );
        window.location.href = createRedirectUrl(targetUrl);
      } catch (error) {
        Logger.warn('Cannot decode the URI: ' + targetUrl);
      }
    },
    partnerName,
  };
}
