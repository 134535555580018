import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState, MouseEvent, useMemo } from 'react';
import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LOCALES } from '../../i18n/constants';

export function LanguageMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { i18n } = useTranslation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const languages = useMemo(() => {
    const formatter = new Intl.DisplayNames(i18n.language, {
      type: 'language',
    });
    return SUPPORTED_LOCALES.map((code) => ({
      code,
      currentLanguageLabel: formatter.of(code),
      targetLanguageLabel: new Intl.DisplayNames(code, { type: 'language' }).of(
        code
      ),
    }));
  }, [i18n.language]);

  const currentLanguage = languages.find(({ code }) => code === i18n.language);

  return (
    <>
      <Button
        variant="text"
        id="languageButton"
        aria-controls={open ? 'languageMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-testid="languageButton"
        sx={{
          textTransform: 'none',
          color: 'primary.contrastText',
          display: 'flex',
        }}
      >
        {currentLanguage?.currentLanguageLabel}
      </Button>
      <Menu
        id="languageMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'languageButton',
        }}
      >
        {languages.map(
          ({ code, targetLanguageLabel, currentLanguageLabel }) => (
            <MenuItem
              data-testid={code}
              value={code}
              key={code}
              onClick={() => {
                changeLanguage(code);
                handleClose();
              }}
            >
              <Box>
                <Typography fontWeight="bold" textTransform="capitalize">
                  {currentLanguageLabel}
                </Typography>
                <Typography>{targetLanguageLabel}</Typography>
              </Box>
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
}
