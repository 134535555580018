import { createLogger } from '@hts/utilities';
import { CURRENT_ENVIRONMENT, DD_SERVICE, VERSION } from '../../../config';

// Logger
export const Logger = createLogger({
  clientToken: 'pubc1227a869b47ba8b221db32fc8caacfc',
  service: DD_SERVICE,
  currentEnvironment: CURRENT_ENVIRONMENT,
  version: VERSION || 'local',
});
