import { Theme } from '@mui/material/styles';
import { defaultTheme } from '../../themes/theme-default';
import { vaTheme } from '../../themes/theme-va';
import { y4Theme } from '../../themes/theme-y4';
import { Partner } from '../constants/partner';

const PartnerThemes = new Map<Partner, Theme>([
  [Partner.HtsAirline, defaultTheme],
  [Partner.VirginAustralia, vaTheme],
  [Partner.Volaris, y4Theme],
]);

export function themeForPartnerId(partnerId: string): Theme {
  return PartnerThemes.get(partnerId as Partner) || defaultTheme;
}
