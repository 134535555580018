import Typography from '@mui/material/Typography';
import { ErrorCode } from '../constants/errorCode';
import { useErrorMessage } from './hooks/useErrorMessage';
import Box from '@mui/material/Box';

export const ErrorDisplay = ({
  errorCode,
}: {
  errorCode: ErrorCode;
  contactFormUrl?: string;
}) => {
  const message = useErrorMessage(errorCode);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="400px"
      textAlign="center"
      sx={{ placeContent: 'center' }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
};
